import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { HelmetProvider, Helmet } from 'react-helmet-async'
import { injectIntl } from 'gatsby-plugin-react-intl'
import { ContentWrapper } from '@components/ui/wrappers/Wrappers'
import NavbarLight from './navbarLight/NavbarLight'
import FooterLight from './FooterLight'
import Seo from '../../seo/SEO'
import _find from 'lodash/find'
// import { CookiesConsent } from '@components/common/CookiesConsent'

const LayoutLight = ({ children, pageName, intl }) => {
  const data = useStaticQuery(graphql`
    query {
      allSeoJson {
        edges {
          node {
            slug
            title
            description
            url
            noindex
          }
        }
      }
    }
  `)

  const seoData = data.allSeoJson.edges.map((item) => {
    return item.node
  })

  const seoMeta = _find(seoData, ['slug', pageName])

  return (
    <HelmetProvider defer={true}>
      <Helmet>
        <script
          id="Cookiebot"
          src="https://consent.cookiebot.com/uc.js"
          data-cbid="4cdb85f8-b43f-4822-8645-c9bf88b0b450"
          type="text/javascript"
        />
        <script
          id="CookieDeclaration"
          src="https://consent.cookiebot.com/4cdb85f8-b43f-4822-8645-c9bf88b0b450/cd.js"
          type="text/javascript"
          async
        />
      </Helmet>
      <div>
        {seoMeta && (
          <Seo
            title={intl.formatMessage({ id: seoMeta.title })}
            description={intl.formatMessage({ id: seoMeta.description })}
            pathname={seoMeta.url}
            noindex={seoMeta.noindex}
          />
        )}
        <NavbarLight slug={pageName} />
        <ContentWrapper>{children}</ContentWrapper>
        <FooterLight />
        {/*<CookiesConsent />*/}
      </div>
    </HelmetProvider>
  )
}

export default injectIntl(LayoutLight)
